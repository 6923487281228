import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import { BoxProps } from 'components/Box/types'
import Button from 'components/Button/Button'
import Image from 'components/Image/Image'
import Text from 'components/Text/Text'
import useMatchBreakpoints from 'hooks/useMatchBreakpoints'
import Column, { ColumnCenter } from 'layout/components/Column'
import { RowMiddle } from 'layout/components/Row'
import React from 'react'
import styled from 'styled-components'
import { LeverageSideImage, PassiveSideImage } from 'svgs'
import { HomeCard, HomeDescription, HomeTitle } from './template'

const ChooseSide: React.FC<BoxProps> = ({ ...props }) => {
  const { isMobile, isMd } = useMatchBreakpoints()
  const isSmallScreen = isMobile || isMd

  const PassiveSide: React.FC<BoxProps> = ({ ...props }) => (
    <StyledWrapSlideImage {...props}>
      <StyledWrapImageLeftSide>
        <PassiveSideImage />
      </StyledWrapImageLeftSide>
      <Image
        style={{ margin: '0 auto', transform: `translateY(${isSmallScreen ? '-15%' : '-35%'}) scale(0.9)` }}
        src="/images/relax.png"
        width={380}
        height={380}
        alt="robos in lab"
      />
    </StyledWrapSlideImage>
  )

  const LeverageSide: React.FC<BoxProps> = ({ ...props }) => (
    <StyledWrapSlideImage {...props}>
      <StyledWrapImageRightSide>
        <LeverageSideImage />
      </StyledWrapImageRightSide>
      <Image
        style={{ margin: '0 auto', transform: `translateY(${isSmallScreen ? '-10%' : '-20%'})` }}
        src="/images/fishing.png"
        width={380}
        height={380}
        alt="robos in beach"
      />
    </StyledWrapSlideImage>
  )

  const PassiveContent = () => (
    <Column flex={1} height="100%">
      <RowMiddle flex={'unset !important'} mb="16px">
        <StyledTag mr="8px" color="#85bfcb">
          Passive Yield
        </StyledTag>
        <StyledTag color="#85bfcb">Low Risks</StyledTag>
      </RowMiddle>

      {isSmallScreen && <PassiveSide />}

      <StyledDescription>
        <strong>First side: </strong>
        For passive liquidity providers who don't want to actively manage their positions and simply want to get APY
        rewards in the same asset.
      </StyledDescription>

      <StyledButton background="#85bfcb" backgroundHover="#6070ff">
        Earn passive APY
      </StyledButton>
    </Column>
  )

  const LeverageContent = () => (
    <Column flex={1} height="100%">
      <RowMiddle flex={'unset !important'} mb="16px">
        <StyledTag mr="8px" color="#f59a86">
          Degening
        </StyledTag>
        <StyledTag color="#f59a86">Manage Risks</StyledTag>
      </RowMiddle>

      {isSmallScreen && <LeverageSide />}

      <StyledDescription>
        <strong>Second side: </strong>
        For leverage users (Farmers, as we call them) who actively farming in top farms, manage their own risks, and are
        very active in DeFi.
      </StyledDescription>

      <StyledButton background="#f59a86" backgroundHover="#ff4ba5">
        Earn passive APY
      </StyledButton>
    </Column>
  )

  return (
    <Box {...props}>
      <HomeTitle color="#000">Choose your side</HomeTitle>
      <HomeDescription mb={['24px', '', '', '40px']}>
        Gentoo Finance is essentially a two-sided lending marketplace: it connects users who want passive safe APY &
        leverage farmers who want to make more with leverage and alpha.
      </HomeDescription>

      {!isSmallScreen && (
        <RowMiddle width="100%" height="100%" alignItems="flex-start !important" mb={['0', '', '', '-150px']}>
          <PassiveSide />
          <LeverageSide />
        </RowMiddle>
      )}

      {!isSmallScreen ? (
        <StyledCard>
          <PassiveContent />
          <Box>
            <StyledLine />
          </Box>
          <LeverageContent />
        </StyledCard>
      ) : (
        <StyledWrapListSlide>
          <StyledWrapSlide>
            <StyledCard>
              <PassiveContent />
            </StyledCard>
          </StyledWrapSlide>
          <StyledWrapSlide>
            <StyledCard>
              <LeverageContent />
            </StyledCard>
          </StyledWrapSlide>
        </StyledWrapListSlide>
      )}
    </Box>
  )
}

const StyledWrapImageLeftSide = styled(Box)`
  width: 100%;
  height: 100%;

  position: relative;
  max-width: 450px;

  svg {
    width: 100%;
    max-height: 450px;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    svg {
      width: 110%;
      left: -20px;
      top: -20px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    svg {
      width: 120%;
      left: -40px;
    }
  }
`

const StyledWrapImageRightSide = styled(Box)`
  width: 100%;
  height: 100%;

  position: relative;
  max-width: 450px;

  svg {
    width: 100%;
    max-height: 450px;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    svg {
      width: 110%;
      left: -40px;
      top: -20px;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    svg {
      width: 140%;
      left: -120px;
    }
  }
`

const StyledTag = styled(Box)<{ color: string }>`
  padding: 10px 16px;
  border-radius: ${({ theme }) => theme.radii.small};
  font-size: 14px;
  font-weight: 500;

  width: max-content;
  color: ${({ color }) => color};
  background: ${({ color }) => color + '12'};
`

const StyledButton = styled(Button)<{ backgroundHover: string }>`
  background: ${({ background }) => background} !important;
  opacity: 1;
  font-size: 16px;
  margin-top: auto;

  &:hover + #test {
    background: ${({ backgroundHover }) => backgroundHover} !important;
  }
`

const StyledCard = styled(HomeCard)`
  background: #fff;
  padding: 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    align-items: flex-start;
    display: grid;
    grid-gap: 24px;

    grid-template-columns: 1fr 100px 1fr;
  }
`

const StyledDescription = styled(Text)`
  font-size: 16px;
  line-height: 1.5;

  color: #000;
  font-weight: 400;
  margin-top: -20px;
  margin-bottom: 20px;
  padding-top: 0;
  margin-top: -30px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 20px;
    padding-top: 24px;
    padding-bottom: 24px;

    margin-top: 0;
    margin-bottom: auto;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 22px;
  }
`

const StyledLine = styled(Box)`
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 50%;
  display: block;

  &:before {
    display: block;
    height: 100%;
    content: '';
    border-right: 1px dashed #d4d6df;
  }
  &:after {
    position: absolute;
    top: calc(50% - 22px);
    left: -22px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 44px;
    height: 44px;

    justify-content: center;

    align-items: center;
    font-size: 14px;
    font-weight: 600;
    content: 'OR';
    color: #697080;
    background-color: #ffffff;
    border: 1px dashed #d4d6df;
    border-radius: 50%;
  }
`

const StyledWrapSlideImage = styled(ColumnCenter)`
  align-items: center;

  width: 100%;
  mask-image: linear-gradient(black 75%, transparent 80%);

  ${({ theme }) => theme.mediaQueries.md} {
    mask-image: unset;
  }
`

const StyledWrapListSlide = styled(Grid)`
  scroll-snap-type: x mandatory;
  grid-gap: 24px;

  overflow-x: auto;
  scroll-behavior: smooth;
  grid-template-columns: repeat(2, calc(100%));

  padding: 32px 0 32px 24px;
  margin: -24px;
  width: calc(100% + 36px);

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledWrapSlide = styled(Box)`
  scroll-snap-align: center;
  scroll-snap-stop: normal;
  margin-right: 24px;
`

export default ChooseSide
