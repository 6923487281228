import Box from 'components/Box/Box'
import Text from 'components/Text/Text'
import useDebounce from 'hooks/useDebounce'
import { useIsomorphicEffect } from 'hooks/useIsomorphicEffect'
import { RowCenter } from 'layout/components/Row'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { MagpieIcon, AnkrIcon, PancakeSwapIcon, WombatIcon, HelioIcon, BiswapIcon, SushiIcon, VenusIcon } from 'svgs'

const platforms = [
  {
    title: 'Pancake',
    icon: PancakeSwapIcon,
    color: '#1fc7d4',
  },
  {
    title: 'Venus',
    icon: VenusIcon,
    color: 'rgba(24, 29, 39, 1)',
  },
  {
    title: 'Wombat',
    icon: WombatIcon,
    color: '#ffdd52',
  },
  {
    title: 'Ankr',
    icon: AnkrIcon,
    color: '#356DF3',
  },
  {
    title: 'Magpie',
    icon: MagpieIcon,
    color: '#8ec6ff',
  },
  {
    title: 'Sushi',
    icon: SushiIcon,
    color: 'rgba(0, 0, 0, 1)',
  },
  {
    title: 'Biswap',
    icon: BiswapIcon,
    color: 'rgba(14, 97, 241, 0.64)',
  },
  {
    title: 'Helio',
    icon: HelioIcon,
    color: 'rgba(242, 170, 76, 1)',
  },
]

const Platform = () => {
  const index = useRef(0)
  const [platform, setPlatform] = useState(platforms[index.current])

  useEffect(() => {
    const interval = setInterval(async () => {
      let platform = platforms[index.current++]
      if (!platform) {
        index.current = 0
        platform = platforms[index.current]
      }
      setPlatform(platform)
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return <Item platform={platform} />
}

const Item = ({ platform }) => {
  const [scale, setScale] = useState(true)

  const debouncePlatform = useDebounce(platform, 350)
  const Icon = debouncePlatform?.icon

  useEffect(() => {
    setScale(false)
  }, [debouncePlatform])

  useIsomorphicEffect(() => {
    setScale(true)
  }, [platform])

  return (
    platform && (
      <Container className='container-animation-platform' background={platform.color} maxWidth={!scale ? '200px !important' : '46px !important'}>
        <StyledWrapLogo className={!scale ? 'active' : 'hide'}>
          <StyledWrapContainerLogo>
            <Icon />
          </StyledWrapContainerLogo>
        </StyledWrapLogo>
        <StyledPositionContent>{debouncePlatform.title}</StyledPositionContent>
      </Container>
    )
  )
}

const Container = styled(Box)`
  position: relative;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  width: 100%;
  height: 46px;

  transition: 0.3s all;
  max-width: 200px;
  overflow: hidden;
`

const StyledPositionContent = styled(Text)`
  word-break: keep-all;
  white-space: nowrap;
  padding: 0 12px;
  font-size: 20px;
`

const StyledWrapLogo = styled(Box)`
  min-width: 36px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
  transition: transform 0.4s;

  svg {
    max-width: 24px;
    max-height: 24px;
  }

  &.active {
    svg {
      opacity: 1;
    }
    transform: rotate(0deg);
  }

  &.hide {
    svg {
      opacity: 0;
      transition: opacity 0.4s;
    }
    transform: rotate(-45deg);
  }
`

const StyledWrapContainerLogo = styled(RowCenter)`
  width: 100%;
  height: 100%;

  svg {
    width: 24px;
    margin: auto;
  }
`
export default React.memo(Platform)
