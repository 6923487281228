import { OVERALL_APY } from 'config'
import { ChainIdEnum } from 'config/networks'
import { NATIVE_TOKENS, WRAP_NATIVE_TOKENS } from 'config/tokenList'
import { TOKENS } from 'config/tokens'
import { Strategy, StrategyProtocol } from 'config/types/strategy'

const chain = ChainIdEnum.BSC
const TokenByChain = TOKENS[chain]

const strategies: Strategy[] = [
  {
    address: '0x7b9574130749d4a53e03204b227710940c4b9d49',
    name: 'Deposit BNB',
    code: 'BoxAnkrBnbWombatMagpie',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [NATIVE_TOKENS[chain], WRAP_NATIVE_TOKENS[chain]],
    yieldToken: [TokenByChain.WOM, TokenByChain.MGP, TokenByChain.ankrBNB],
    borrows: [TokenByChain.BUSD, TokenByChain.USDT],
    protocols: [StrategyProtocol.Ankr, StrategyProtocol.Magpie, StrategyProtocol.Wombat],
    description: 'Open this strategy with just 1 click by providing BNB and borrowing any tokens below:',
    guide: {
      image: '/images/guide/bnb-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: OVERALL_APY,
  },
  {
    address: '0x4FD5F544c43CBa80b9F13f12AF8a2cf97423E44F',
    name: 'Deposit ETH',
    code: 'BoxAnkrEthWombatMagpie',
    description: 'Open this strategy with just 1 click by providing ETH and borrowing any tokens below:',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [TokenByChain.ETH],
    yieldToken: [TokenByChain.WOM, TokenByChain.MGP, TokenByChain.ankrETH],
    borrows: [TokenByChain.BUSD, TokenByChain.USDT],
    protocols: [StrategyProtocol.Ankr, StrategyProtocol.Magpie, StrategyProtocol.Wombat],

    guide: {
      image: '/images/guide/eth-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: OVERALL_APY,
  },
]

export default strategies
