import { RowCenter } from 'layout/components/Row'
import React from 'react'
import styled from 'styled-components'
import { HomeContainer, HomeWrapper } from './template'
import IntroLeverage from './IntroLeverage'
import StrategyTablePreview from './StrategyTablePreview'
import { BoxProps } from 'components/Box/types'

const Leverage: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <StyledWrapper {...props}>
      <StyledContainer>
        <IntroLeverage mb="32px" />
        <StrategyTablePreview />
      </StyledContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(HomeWrapper)`
  background: linear-gradient(118deg, rgb(17, 21, 35) 0%, rgb(47, 59, 90) 100%);
`

const StyledContainer = styled(HomeContainer)``

export default Leverage
