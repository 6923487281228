import Text from 'components/Text/Text'
import styled from 'styled-components'
import OverviewAmount from './OverviewAmount'
import { HomeContainer, HomeWrapper } from './template'
import Platform from './Platform'
import Box from 'components/Box/Box'

const Overview = () => {
  return (
    <StyledWrapper>
      <StyledContainer>
        <Text
          lineHeight={['40px', '', '50px', '', '60px', '', '76px']}
          fontSize={['24px', '24px', '32px', '', '44px', '', '52px']}
          fontWeight={300}
        >
          <span id="heading">Composable Leverage Protocol on</span>
          BNB Chain DeFi-Native Ecosystem
        </Text>

        <Text
          my={['24px', '', '40px', '', '56px']}
          lineHeight={['32px', '', '38px', '', '44px', '', '50px']}
          fontSize={['16px', '', '20px', '', '24px', '', '28px']}
          fontWeight={400}
          style={{
            wordWrap: 'break-word',
          }}
        >
          Earn passive APY by supplying one-sided liquidity to Gentoo - or maximize DeFi rewards with leverage in{' '}
          <StyledWrapPlatform display="contents">
            <Platform />
          </StyledWrapPlatform>
        </Text>

        {/* <OverviewAmount /> */}
      </StyledContainer>
    </StyledWrapper>
  )
}

const StyledWrapper = styled(HomeWrapper)`
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: -100px;
  }

  border-radius: 0 !important;
  background: linear-gradient(118deg, rgb(17, 21, 35) 0%, rgb(47, 59, 90) 100%);
`

const StyledContainer = styled(HomeContainer)`
  padding-bottom: 60px;
  overflow: hidden;

  #heading {
    margin-right: 6px;
    font-weight: 600;
    display: inline;
    ${({ theme }) => theme.mediaQueries.sm} {
      display: block;
    }
  }
`
const StyledWrapPlatform = styled(Box)`
  display: contents;

  .container-animation-platform {
    transform: translateY(6px);

    ${({ theme }) => theme.mediaQueries.sm} {
      transform: translateY(2px);
    }
  }
`
export default Overview
