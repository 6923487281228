import Box from 'components/Box/Box'
import Flex from 'components/Box/Flex'
import Grid from 'components/Box/Grid'
import { BoxProps } from 'components/Box/types'
import Card from 'components/Card/Card'
import Text from 'components/Text/Text'
import React from 'react'
import styled from 'styled-components'
import theme from 'theme'
import { HomeDescription, HomeTitle } from './template'
import Image from 'components/Image/Image'

const IntroLeverage: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <HomeTitle textAlign="left">Get ×10 leverage and use it across DeFi</HomeTitle>
      <HomeDescription color={theme.colors.textSubtle + ' !important'} textAlign="left">
        Are you a farmer, a strategist, ... doesn't matter! If you have alpha and want to earn more by using leverage -
        use our strategies. It's helps you to do everything you already love, but with more capital.
      </HomeDescription>

      <StyledWrapListSlide my="48px" gridGap="24px">
        <StyledCard>
          <Text lineHeight={1.6} fontSize="16px">
            Organic liquid APY from the largest $-million protocols.
          </Text>
          <Flex justifyContent="flex-end" mt="16px" ml="auto">
            <Image width={60} height={60} src="/images/leverage-1.png" />
          </Flex>
        </StyledCard>
        <StyledCard>
          <Text lineHeight={1.6} fontSize="16px">
            Choose between the highest yields and position safety.
          </Text>
          <Flex justifyContent="flex-end" mt="16px" ml="auto">
            <Image width={60} height={60} src="/images/leverage-2.png" />
          </Flex>
        </StyledCard>
        <StyledCard>
          <Text lineHeight={1.6} fontSize="16px">
            Delta-neutral, cross-collateral, or whatever else YOU decide.
          </Text>
          <Flex justifyContent="flex-end" mt="16px" ml="auto">
            <Image width={60} height={60} src="/images/leverage-3.png" />
          </Flex>
        </StyledCard>
      </StyledWrapListSlide>
    </Box>
  )
}

const StyledCard = styled(Card)`
  background: transparent;
  border: 1px solid #858995;
  border-radius: ${({ theme }) => theme.radii.small};
  padding: 30px;

  scroll-snap-align: center;
  scroll-snap-stop: normal;
`

const StyledWrapListSlide = styled(Grid)`
  scroll-snap-type: x mandatory;
  grid-gap: 24px;

  overflow-x: auto;
  overflow-y: hidden;

  scroll-behavior: smooth;
  grid-template-columns: repeat(3, calc(50%));

  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
`

export default IntroLeverage
