import { RowCenter } from 'layout/components/Row'
import styled from 'styled-components'
import ChooseSide from './ChooseSide'
import { HomeContainer, HomeWrapper } from './template'
import EarnYieldTable from './EarnYieldTable'
import React from 'react'
import { BoxProps } from 'components/Box/types'

const SidePlatform: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <HomeWrapper {...props}>
      <HomeContainer>
        <ChooseSide mb="60px" />
        <EarnYieldTable />
      </HomeContainer>
    </HomeWrapper>
  )
}

export default SidePlatform
