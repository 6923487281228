import React from 'react'
import { HomeContainer, HomeWrapper } from './template'
import { AutoRow, RowCenter } from 'layout/components/Row'
import Text from 'components/Text/Text'
import Link from 'components/Link/Link'
import { socials } from 'config/navigation'

const Footer = () => {
  return (
    <HomeWrapper>
      <HomeContainer py="60px !important">
        <AutoRow justifyContent="center" gap="16px">
          {socials.map((social) => (
            <Link external key={`link-social-${social.label}`} href={social.href} fontSize="16px" color="textSubtle">
              {social.label}
            </Link>
          ))}
        </AutoRow>
      </HomeContainer>
    </HomeWrapper>
  )
}

export default Footer
