import Box from 'components/Box/Box'
import Grid from 'components/Box/Grid'
import Button from 'components/Button/Button'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Text from 'components/Text/Text'
import { strategies } from 'config/strategy'
import { urlRoute } from 'config/route'
import { Strategy } from 'config/types/strategy'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { RowFixed } from 'layout/components/Row'
import { useMemo } from 'react'
import styled from 'styled-components'
import { HomeGreyCard } from './template'
import { usePools } from 'state/pools/hooks'
import { useBoxInfo } from 'hooks/useBoxesInfo'
import { BIG_ZERO, MAX_LEVERAGE_MULTIPLIER } from 'config'
import { getOverallAPY } from 'utils/reciept-base'
import Skeleton from 'components/Skeleton/Skeleton'

const RowStrategy = (strategy: Strategy) => {
  const boxInfo = useBoxInfo(strategy)
  const pools = usePools()

  const maxROI = useMemo(() => {
    const mapStrategyBorrow = strategy.borrows.reduce((state, borrow) => {
      state[borrow.address] = borrow
      return state
    }, {})

    let borrowRate = pools.data[0]?.borrowRate || BIG_ZERO // default value

    if (borrowRate.lte(0)) return null

    pools.data.forEach((pool) => {
      if (mapStrategyBorrow[pool.token.address] && borrowRate.gt(pool.borrowRate)) {
        borrowRate = pool.borrowRate
      }
    })
    return getOverallAPY(MAX_LEVERAGE_MULTIPLIER, borrowRate, boxInfo?.apyTotal || strategy.apy).toFixed(2)
  }, [pools, strategy, boxInfo])

  return (
    <StyledRow as="a" href={urlRoute.strategyDetail({ code: strategy.code }).to}>
      <StyledCol>
        <CurrencyLogo token={strategy.depositTokens[0]} />
        <Text fontSize={['14px', '', '16px']} fontWeight={400} ml="12px">
          {strategy.name}
        </Text>
      </StyledCol>
      <StyledCol>
        <Text mx="auto" fontSize={['14px', '', '16px']} fontWeight={400}>
          x{strategy.range[0]}-{strategy.range[1]}
        </Text>
      </StyledCol>
      <StyledCol display={['none  !important', '', 'flex !important']}>
        {maxROI ? (
          <Text fontSize={['14px', '', '16px']} fontWeight={400}>
            {maxROI?.toString()}%
          </Text>
        ) : (
          <Skeleton width="64px" height="20px" />
        )}
      </StyledCol>
      <StyledCol>
        <Text fontSize={['14px', '', '16px']} fontWeight={400}>
          {strategy.borrows.map((borrow) => borrow.name).join(', ')}
        </Text>
      </StyledCol>
      <StyledCol display={['none !important', '', '', 'flex !important']}>
        <Text fontSize={['14px', '', '16px']}>Start earning</Text>
      </StyledCol>
    </StyledRow>
  )
}

const StrategyTablePreview = () => {
  const { chainId } = useActiveWeb3React()
  const listStrategy = useMemo(() => strategies[chainId] ?? [], [chainId])

  return (
    <HomeGreyCard>
      <Box mb="8px">
        <StyledTemplate>
          <StyledCol>STRATEGY</StyledCol>
          <StyledCol mx="auto">LEVERAGE</StyledCol>
          <StyledCol display={['none  !important', '', 'flex !important']}>MAX APY</StyledCol>
          <StyledCol>BORROW</StyledCol>
          <StyledCol display={['none  !important', '', '', 'flex !important']}></StyledCol>
        </StyledTemplate>
        {listStrategy.map((strategy) => (
          <RowStrategy key={`strategy-row-${strategy.address}`} {...strategy} />
        ))}
      </Box>

      <Button scale="lg" as="a" href={urlRoute.strategies().to} width="100%" background={'#373c4f !important'}>
        View all strategies in the dApp
      </Button>
    </HomeGreyCard>
  )
}

const StyledCol = styled(RowFixed)`
  font-size: 12px;
  text-align: left;
  align-items: center;

  color: ${({ theme }) => theme.colors.textSubtle};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

const StyledTemplate = styled(Grid)`
  padding: 16px;
  grid-template-columns: 1fr 1fr 1fr;

  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 1fr 100px 100px 1fr;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 0.8fr 0.8fr 1fr 120px;
  }
`

const StyledRow = styled(StyledTemplate)`
  border-radius: ${({ theme }) => theme.radii.small};

  &:hover {
    background: ${({ theme }) => theme.colors.hover};
  }
`
export default StrategyTablePreview
