import Page from 'components/Page'
import Leverage from './Leverage'
import Overview from './Overview'
import SidedPlatform from './SidePlatform'
import Footer from './Footer'
import Integration from './Integration'

const Home = () => {
  return (
    <Page maxWidth="100%" px="0 !important">
      <Overview />
      <SidedPlatform />
      <Leverage />
      <Integration />
      {/* <Audits mb="-60px" /> */}
      <Footer />
    </Page>
  )
}

export default Home
