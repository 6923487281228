import Home from 'views/Home'

const Page = (props) => {
  return <Home {...props} />
}

export async function getStaticProps() {
  return {
    props: {
      layoutProps: { paddingBottom: '0 !important' },
    },
  }
}

export default Page
