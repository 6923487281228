import { ChainIdEnum } from 'config/networks'
import { Strategy } from 'config/types/strategy'
import { NATIVE_TOKENS } from 'config/tokenList'
import { TOKENS } from 'config/tokens'
import { OVERALL_APY, ZERO_ADDRESS } from 'config'

const chain = ChainIdEnum.SEPOLIA

const strategies: Strategy[] = [
  {
    address: ZERO_ADDRESS,
    name: 'Yearn WETH',
    code: '',
    range: [1, 10],
    fee: 0,

    depositTokens: [NATIVE_TOKENS[chain]],
    protocols: [],
    yieldToken: [],
    description: '',
    borrows: [TOKENS[chain].ETH, TOKENS[chain].WETH],
    guide: {
      description: '',
      image: '',
    },
    apy: OVERALL_APY,
  },
]

export default strategies
