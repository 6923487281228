import Box from 'components/Box/Box'
import { BoxProps } from 'components/Box/types'
import Text from 'components/Text/Text'
import React from 'react'
import styled from 'styled-components'
import PoolTable from 'views/Pools/components/PoolTable'
import { TableRowInnerContainer } from 'views/Pools/components/styled'
import { HomeCard, HomeDescription, HomeTitle } from './template'

const EarnYieldTable: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <HomeTitle color="#000">Provide capital and earn yield</HomeTitle>
      <HomeDescription>
        Earn passive APY without impermanent loss. No lockup on your capital or withdrawal fees. Just sit back and earn
        yield!
      </HomeDescription>
      <StyledTable mt={['24px', '', '', '60px']}>
        <PoolTable redirect={true} />
      </StyledTable>
    </Box>
  )
}

const StyledTable = styled(HomeCard)`
  ${Text} {
    color: ${({ theme }) => theme.colors.secondBackground};
  }
  svg {
    fill: ${({ theme }) => theme.colors.secondBackground};
  }
  ${TableRowInnerContainer} {
    &:hover {
      background: rgb(247, 248, 249);
    }
  }
`

export default EarnYieldTable
