import Box from 'components/Box/Box'
import Card from 'components/Card/Card'
import Text from 'components/Text/Text'
import { RowCenter } from 'layout/components/Row'
import styled from 'styled-components'

export const HomeContainer = styled(Box)`
  max-width: ${({ theme }) => theme.containerSide}px;
  padding: 50px 16px 120px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 100px 32px 100px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 100px 60px 100px;
  }
`

export const HomeWrapper = styled(RowCenter)`
  background: #fff;
  border-radius: ${({ theme }) => theme.radii.small} ${({ theme }) => theme.radii.small} 0 0;
  position: relative;
  z-index: 1;
  margin-bottom: -20px;
`

export const HomeTitle = styled(Text).attrs({ as: 'h1' })`
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 8px;
  color: ${({ theme, color = '#000' }) => theme.colors[color] || color} !important;
  text-align: left;

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 26px;
    line-height: 1.5;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    text-align: ${({ textAlign }) => textAlign || 'center'};

    font-size: 32px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 44px;
  }
`

export const HomeDescription = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color ${({ theme }) => theme.colors.disabled};
  font-weight: 400;
  text-align: left;

  
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
    line-height: 28px;
  }

  
  ${({ theme }) => theme.mediaQueries.md} {
    text-align: ${({ textAlign }) => textAlign || 'center'};
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 22px;
    line-height: 32px;
  }
`

export const HomeCard = styled(Card)`
  max-width: ${({ theme }) => theme.containerSide}px;

  background: #fff;
  border-radius: ${({ theme }) => theme.radii.small};
  box-shadow: 0 10px 40px rgb(0 0 0 / 15%);
`

export const HomeGreyCard = styled(Card)`
  background: #40465b;
  box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
  border-radius: ${({ theme }) => theme.radii.small};
`
