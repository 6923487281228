import { ChainIdEnum } from 'config/networks'
import { Strategy, StrategyProtocol } from 'config/types/strategy'
import { NATIVE_TOKENS, WRAP_NATIVE_TOKENS } from 'config/tokenList'
import { TOKENS } from 'config/tokens'
import { OVERALL_APY } from 'config'

const chain = ChainIdEnum.BSC_TESTNET
const TokenByChain = TOKENS[chain]

const strategies: Strategy[] = [
  {
    address: '0xA31EA9Bd5cEB3c198b4ef0DcE572C03B59D94fAD',
    name: 'Deposit BNB',
    code: 'BoxAnkrBnbWombatMagpie',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [NATIVE_TOKENS[chain], WRAP_NATIVE_TOKENS[chain]],
    yieldToken: [TokenByChain.WOM, TokenByChain.MGP, TokenByChain.ankrBNB],
    borrows: [TokenByChain.USDT, TokenByChain.BUSD],
    protocols: [StrategyProtocol.Ankr, StrategyProtocol.Magpie, StrategyProtocol.Wombat],
    description: 'Open this strategy with just 1 click by providing BNB and borrowing any tokens below:',
    guide: {
      image: '/images/guide/bnb-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: OVERALL_APY,
  },
  {
    address: '0x67C272f6BBA4227aE1786E10233FEa0BD3d45A1A',
    name: 'Deposit ETH',
    code: 'BoxAnkrEthWombatMagpie',
    description: 'Open this strategy with just 1 click by providing ETH and borrowing any tokens below:',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [TokenByChain.ETH],
    yieldToken: [TokenByChain.WOM, TokenByChain.MGP, TokenByChain.ankrETH],
    borrows: [TokenByChain.USDT, TokenByChain.BUSD],
    protocols: [StrategyProtocol.Ankr, StrategyProtocol.Magpie, StrategyProtocol.Wombat],

    guide: {
      image: '/images/guide/eth-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: 15,
  },
  {
    address: '0xECe66Ae8E12089533465A349679C0A584eC87a3B',
    name: 'Deposit VAI',
    code: 'VenusVaiPool',
    description: 'Open this strategy with just 1 click by providing VAI and borrowing any tokens below:',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [TokenByChain.VAI],
    yieldToken: [TokenByChain.VAI, TokenByChain.XVS],
    borrows: [TokenByChain.USDT, TokenByChain.BUSD],
    protocols: [StrategyProtocol.Venus],

    guide: {
      image: '/images/guide/vai-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: 11,
  },
  {
    address: '0xfd86B6C7988D7874B44A46320B9dBCcC277911F7',
    name: 'Deposit BSW',
    code: 'BSW',
    description: 'Open this strategy with just 1 click by providing BSW and borrowing any tokens below:',
    fee: 2.5,
    range: [1, 10],

    depositTokens: [TokenByChain.BSW],
    yieldToken: [TokenByChain.BSW],
    borrows: [TokenByChain.USDT, TokenByChain.BUSD],
    protocols: [StrategyProtocol.Biswap],

    guide: {
      image: '/images/guide/vai-ankr-strategy.png',
      description:
        'Ape into complicated strategies with just 1 click! You decide your debt asset (what you borrow) as well as your leverage exposure. Our 1-click multicall will then swap-deposit-stake into a farm of your choosing, while finding the best route for it. Happy farming!',
    },
    apy: 32,
  },
]

export default strategies
