import { ChainIdEnum } from 'config/networks'
import { Strategy } from 'config/types/strategy'
import sepolia from './sepolia'
import bscTestnet from './bscTestnet'
import bscMainnet from './bscMainnet'

// ADD NETWORK
export const strategies: {
  [key in ChainIdEnum]: Strategy[]
} = {
  [ChainIdEnum.SEPOLIA]: sepolia,
  [ChainIdEnum.BSC_TESTNET]: bscTestnet,
  [ChainIdEnum.BSC]: bscMainnet,
}
