import { BoxProps } from 'components/Box/types'
import { HomeContainer, HomeDescription, HomeTitle, HomeWrapper } from './template'
import { IntegrationImage, IntegrationMobileImage } from 'svgs'
import styled from 'styled-components'
import { RowCenter } from 'layout/components/Row'

const Integration: React.FC<BoxProps> = ({ ...props }) => {
  return (
    <HomeWrapper {...props}>
      <StyledHomeContainer>
        <HomeTitle color="#000">Integrations</HomeTitle>
        <HomeDescription>Up to ×10 leverage is available now on Venus, Ankr, Wombat, Magpie, and more!</HomeDescription>
        <StyledWrapImage>
          <IntegrationImage className="desktop" />
          <IntegrationMobileImage className="mobile" />
        </StyledWrapImage>
      </StyledHomeContainer>
    </HomeWrapper>
  )
}

const StyledHomeContainer = styled(HomeContainer)`
  max-width: 100%;
  justify-content: center;

  padding: 50px 16px 0;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 100px 32px 100px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    padding: 100px 60px 100px;
  }
`

const StyledWrapImage = styled(RowCenter)`
  margin: 24px 0;

  .desktop {
    display: none;
  }

  .animate-path {
    stroke-dashoffset: 0;
    animation: animate-path 30s linear infinite;
  }

  @keyframes animate-path {
    to {
      stroke-dashoffset: 100%;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0;

    svg {
      width: 1000px;
    }
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }

    .animate-path {
      animation: animate-path 30s linear infinite;
    }

    @keyframes animate-path {
      to {
        stroke-dashoffset: -100%;
      }
    }
  }
`

export default Integration
